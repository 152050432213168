


.user-list table{

    width: 100%;
}

.table-row:nth-child(even){
    background-color: #f2f2f2;
}

.table-row:hover {background-color: #ddd;}

.table-row td{
    padding: 5px 10px;
    height: 45px;    
}



.table-header th{
    background-color: rgb(51, 51, 50);
    height: 35px;
    padding: 10px;
    text-align: left;
    color: white;

}

.user-list h1{
    margin-bottom: 15px;
    padding-left: 5px;
}