


.navbar{
    height: 60px;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid rgb(255, 255, 255,0.5);
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 30px;
    background-color: rgb(0, 0, 0,0.6);
    z-index: 99;
}
/* .navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
} */

.navbar-logo{
    color: rgb(235, 234, 229);
    padding: 5px;
    font-size: 30px;
    display: flex;
    align-items: center;
    font-family: 'Dancing Script', cursive;
    transition: ease-in-out all 0.3s;
}

.navbar-logo:hover{
    transform: scale(1.15);
    cursor: pointer;
}

.navbar-ul{
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: 100px;
    /*
    order: 1;
    */
}

.nav-items{
    margin: 0 10px;
}

.links{
    text-decoration: none;
    color: white;
    font-size: 17px;
    transition: ease-in-out all 0.3s;
    display: block;
}

.links:hover{
    transform:scale(1.1);
    color: rgb(199, 181, 78);
}

.date-and-phone{
    color: white;
    display: flex;
    align-items: center;
    margin-left: 50px;
}

.date-and-phone label:hover{
    cursor: pointer;
}

.date-and-phone i{
  margin-right: 5px;
}

.nav-date{
    margin-left: 20px;
    /* color: rgb(112, 195, 112); */
    color: rgb(207, 191, 117);

}

.login-reg-links{
    display: flex;
    align-items: center;
    margin-left: auto;

}

.login-link{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    padding: 3px;
    background-color: white;
    color: rgb(83, 78, 3);
    border-radius: 5px;
    font-size: 15px;

}

.log-reg-separator{
    color: white;
    margin: 0 8px;
}

.user-loggedIn{
    padding: 5px;
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    font-size: 18px;
    margin-right: 30px;
    display: none;
}

.user-loggedIn button{
    margin-left: 10px;
    padding: 5px;
    background-color: indianred;
    border-radius: 5px;
    border: none;
    width: 100px;
    height: 25px;
    color: white;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}

.user-loggedIn button:hover{
    background-color: rgb(189, 61, 61);
}

.cp{
    margin-left: 10px;
    padding: 5px 10px;
    background-color: rgb(161, 136, 21);
    border-radius: 5px;
    border: none;
    height: 30px;
    color: white;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    display: none; 
}

.cp:hover{
    color: white;
}

.up{
    margin-left: 10px;
    padding: 5px 10px;
    background-color: rgb(161, 136, 21);
    border-radius: 5px;
    border: none;
    height: 30px;
    color: white;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    display: none;
}

.up:hover{
    color: white;
}

.humburger{
    padding: 8px;
    height: 30px;
    width: 45px;
    transition: 0.3s all ease-in-out;
    margin-left: auto;
    display: none;
    cursor: pointer;
}

.humburger-active{
    border: 2px solid rgb(255, 214, 29)!important;
}

.line{
    margin-bottom: 4px;
    border: 2px solid white;
    border-radius: 4px;
}

.flashing-ad {
    position: relative;
    /*left: 50%;
    transform: translateX(-50%);
    order: 2;
    */
    margin: 0 20px; 
    background-color: #059700;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    overflow-wrap: break-word;
    word-wrap: break-word;
    max-width: 250px;
    text-align: center;
    overflow: hidden;
}

.star {
  position: absolute;
  width: 4px;
  height: 4px;
  background: white;
  box-shadow: 0 0 3px #fff, 0 0 5px #fff;
  border-radius: 50%;
  animation: twinkle 1.5s infinite alternate;
  z-index: 1000;
}


/* .star:nth-child(2) { --i: 2; top: calc(var(--i) * 6.25%); left: calc(var(--i) * 6.25%); animation-delay: calc(var(--i) * 0.3s); }
.star:nth-child(15) { --i: 15; bottom: calc(var(--i) * 6.25%); right: calc(var(--i) * 6.25%); animation-delay: calc(var(--i) * 0.6s); } */

/* Top quadrant stars */
.star:nth-child(1) { top: 5%; left: 15%; animation-delay: 0.3s; }
.star:nth-child(2) { top: 8%; left: 45%; animation-delay: 0.7s; }
.star:nth-child(3) { top: 12%; left: 75%; animation-delay: 0.2s; }
.star:nth-child(4) { top: 15%; left: 92%; animation-delay: 0.5s; }

/* Middle-top quadrant stars */
.star:nth-child(5) { top: 25%; left: 8%; animation-delay: 0.4s; }
.star:nth-child(6) { top: 28%; left: 35%; animation-delay: 0.8s; }
.star:nth-child(7) { top: 32%; left: 65%; animation-delay: 0.3s; }
.star:nth-child(8) { top: 35%; left: 85%; animation-delay: 0.6s; }

/* Middle-bottom quadrant stars */
.star:nth-child(9) { top: 55%; left: 12%; animation-delay: 0.5s; }
.star:nth-child(10) { top: 58%; left: 42%; animation-delay: 0.9s; }
.star:nth-child(11) { top: 62%; left: 72%; animation-delay: 0.4s; }
.star:nth-child(12) { top: 65%; left: 95%; animation-delay: 0.7s; }

@keyframes twinkle {
  0%, 100% {
    opacity: 1;
    transform: scale(1.5);
  }
  50% {
    opacity: 0.2;
    transform: scale(0.5);
  }
}
  
.flashing-ad a {
    color: #000;
    text-decoration: none;
    white-space: nowrap;
}
  
.flashing-ad.flash-red {
    overflow: hidden;
    background-color: #c90a0a;
}

.flashing-ad.flash-blue {
    overflow: hidden;
    background-color: #0a6dc9;
}

.flashing-ad.flash-pink {
    overflow: hidden;
    background-color: #c90aac;
}

.ad-container {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.ad-caption {
   color: white;
   font-size: 14px;
   white-space: nowrap;
}
  

/* --------------side navbar ----------- */





@media only screen and (max-width: 1350px){

    .date-and-phone{
        display: none;
    }

    .disappear{
        display: none;
    }

    .humburger{
        display: block;
        
    }

    .flashing-ad {
        margin: 0 10px;
    }

    /* .navbar-content {
        justify-content: center;
    } */

    #user-profile{
        margin: 0px 0px;

    }

    #control-panel{
        margin: 0px 0px;
    }
    .navbar-logo {
        flex-shrink: 1;
        font-size: 25px;
    }
    .star {
      width: 3px;
      height: 3px;
    }
}


@media only screen and (max-width: 800px){

    .navbar-ul{
        display: none;
  
    }
    /*
    .ad-caption {
        display: none;
        font-size: 8px;
        margin: 0px 0px;
    }*/

    .flashing-ad {
        font-size: 14px; /* Smaller font for mobile */
        padding: 3px 8px;
        max-width: 250px;
        margin: 0 10px;
        flex-shrink: 1; /* Allow text to shrink */
    }

    .navbar {
        justify-content: space-between;
    }

    .navbar-logo {
        flex-shrink: 1;
        font-size: 20px;
    }
    .star {
      width: 2.5px;
      height: 2.5px;
    }
}
@media only screen and (max-width: 480px) {
    /*
    .ad-caption {
        display: none;
        font-size: 8px;
        margin: 0px 0px;
    }*/

    .flashing-ad {
        font-size: 12px;
        padding: 2px 6px;
        max-width: 250px; /* Prevent overflow */
        text-align: center;
        white-space: normal; /* Enable text wrapping */
    }
    .navbar-logo {
        flex-shrink: 1;
        font-size: 15px;
    }
    .star {
      width: 2px;
      height: 2px;
    }
}