.snow-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 60px;
    left: 0;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
    cursor: pointer;
    transition: transform 0.3s ease;
}
.snowman-container:hover {
    transform: translateX(-50%) scale(1.1); /* Adjust scale for hover effect */
}
.snowman-container:active {
  transform: translateX(-50%) scale(0.95); /* Slight press effect */
}
/* Accessibility focus styles */
.snowman-container:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.snowflake {
    position: absolute;
    color: white;
    font-size: 1em;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
    animation: fall 12s linear infinite;
    animation-delay: calc(var(--i) * -0.5s);
    transform-origin: center;
}

@keyframes fall {
    0% {
        opacity: 1;
        transform: translate(0, 0) rotate(0deg);
    }
    25% {
        transform: translate(15px, 25vh) rotate(45deg);
        opacity: 0.8;
    }
    50% {
        transform: translate(-15px, 50vh) rotate(180deg);
        opacity: 0.6;
    }
    75% {
        transform: translate(10px, 75vh) rotate(270deg);
        opacity: 0.4;
    }
    100% {
        opacity: 0;
        transform: translate(-5px, 100vh) rotate(360deg);
    }
    /* 0% {
        top: -10%;
        opacity: 1;
    }
    100% {
        top: 100%;
        opacity: 0.3;
    } */
}
