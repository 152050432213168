
.hero{
    height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url("../../../assets/hero.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.hero-text{
    text-align: center;
}

.hero-h1{
    font-size: 50px;
    color: rgb(255, 255, 255);
}

.hero-para-div{
    display: flex;
    justify-content: center;
}

.hero-para{
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 8px;
    padding:0 20px;
    width: 60%;
    padding: 20px;
    line-height: 40px;
}

.hero-btn{
    width: 150px;
    height:35px;
    background-color: rgb(161, 136, 21);
    border-radius: 20px;
    border:none;
    margin-top: 20px;
    color: white;
    transition: all ease-in-out 0.3s;
}

.hero-btn:hover{
    cursor: pointer;
    background-color: rgb(128, 106, 10);
}

/* Snowman graphic */
.snowman-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 2000;
}

.snowman {
  position: relative;
  z-index: 2000;
}

.head {
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  position: relative;
}

.eye {
  width: 5px;
  height: 5px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 20px;
}

.eye.left { left: 15px; }
.eye.right { right: 15px; }

.carrot {
  width: 0;
  height: 0;
  border-left: 15px solid orange;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: 25px;
  left: 25px;
}

.body {
  width: 70px;
  height: 70px;
  background: white;
  border-radius: 50%;
  position: relative;
  left: -10px;
  top: -5px;
}

.base {
  width: 90px;
  height: 90px;
  background: white;
  border-radius: 50%;
  position: relative;
  left: -20px;
  top: -10px;
}

.button {
  width: 8px;
  height: 8px;
  background: black;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.button:nth-child(1) { top: 15px; }
.button:nth-child(2) { top: 30px; }
.button:nth-child(3) { top: 45px; }

.arms {
  width: 40px;
  height: 3px;
  background: brown;
  position: absolute;
}

.left-arm {
  transform: rotate(45deg);
  left: -30px;
  top: 70px;
}

.right-arm {
  transform: rotate(-45deg);
  right: 0px;
  top: 70px;
}

/* Add shadow effect */
.snowman::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: -30px;
  width: 110px;
  height: 20px;
  background: rgba(0,0,0,0.1);
  border-radius: 50%;
}

/* Snow ground effect */
.snow-ground {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(to bottom, 
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0.8) 50%,
    rgba(255,255,255,1) 100%
  );
  z-index: 2000;
}

/* Snow pile beneath snowman */
.snow-pile {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 40px;
  background: white;
  border-radius: 50% 50% 0 0;
  z-index: 2000;
  box-shadow: 
    0 -10px 20px rgba(255,255,255,0.8),
    0 -5px 15px rgba(255,255,255,0.6);
}

/* Scattered snow spots */
.snow-spots {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2000;
}

.snow-spot {
  position: absolute;
  width: 8px;
  height: 8px;
  background: rgba(255,255,255,0.8);
  border-radius: 50%;
}

/* Create multiple snow spots */
.snow-spot:nth-child(1) { left: 10%; bottom: 40px; }
.snow-spot:nth-child(2) { left: 25%; bottom: 30px; }
.snow-spot:nth-child(3) { left: 40%; bottom: 35px; }
.snow-spot:nth-child(4) { left: 60%; bottom: 25px; }
.snow-spot:nth-child(5) { left: 75%; bottom: 45px; }
.snow-spot:nth-child(6) { left: 90%; bottom: 30px; }

/* Media Queries for Responsiveness */

@media only screen and (min-width: 1600px){

  .hero-h1{
      font-size: 55px;
      margin-bottom: 10px;
  }

  .hero-para{
      font-size: 25px;
      margin-bottom: 7px;
  }

  .hero-btn{
      width: 200px;
      height:40px;
      font-size: 18px;
  }
}

@media only screen and (max-width: 768px){

  .hero {
    background-attachment: scroll;      
  }
  .hero-h1{
      font-size: 45px;
      padding: 0px 15px;
  }
  .hero-para{
      font-size: 24px;
      width: 90%;
      padding: 20px;
      line-height: 40px;
  }
  .snowman-container,
  .snow-ground,
  .snow-pile,
  .snow-spots {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .hero {
    background-attachment: scroll;      
  }
  .snowman-container,
  .snow-ground,
  .snow-pile,
  .snow-spots {
    display: none;
  }
}

@media only screen and (max-device-width: 812px) {
  .hero {
    background-attachment: scroll;      
  }
  .snowman-container,
  .snow-ground,
  .snow-pile,
  .snow-spots {
    display: none;
  }
}

@media only screen and (max-device-width: 480px) {
  .hero {
    background-attachment: scroll;      
  }
  .snowman-container,
  .snow-ground,
  .snow-pile,
  .snow-spots {
    display: none;
  }
}